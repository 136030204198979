// import {TweenMax} from "gsap/TweenMax";
import ScrollMagic from "scrollmagic";
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

var tl = new TimelineMax();

var controller = new ScrollMagic.Controller();

var scene_hero = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onEnter',
  // duration: 800,
  offset: 0
})
  // .addIndicators()
  .addTo(controller);

scene_hero.on('enter', () => {
  //triggerElementを過ぎたとき
  setTimeout(function () {
    //$(".top-hero").addClass("is-active");
  }, 500);
});
scene_hero.on('leave', () => {
  //triggerElementを抜けたとき
});


var topScroll_display = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onLeave',
  duration: 400,
  offset: 0
}).addTo(controller);
topScroll_display.on('enter', () => {
  $(".c-scrollPageTop").removeClass("-display");
});
topScroll_display.on('leave', () => {
  $(".c-scrollPageTop").addClass("-display");
});

var topScroll_opacity = new ScrollMagic.Scene({
  triggerElement: "body",
  triggerHook: 'onLeave',
  duration: 800,
  offset: 0
}).addTo(controller);
topScroll_opacity.on('enter', () => {
  $(".c-scrollPageTop").removeClass("-opacity");
});
topScroll_opacity.on('leave', () => {
  $('.c-scrollPageTop').addClass('-opacity');
});