

//---------------------------
//ヘッダーのディレクトリ判定
//---------------------------
let loc = window.location.pathname;
let dir = loc.substring(0, loc.lastIndexOf('/'));
//console.log(dir);

if (dir.match(/about/)) {
  $("#about").toggleClass("js-on-directory");
} else if(dir.match(/topics/)) {
  $("#topics").toggleClass("js-on-directory");
} else if(dir.match(/service/)) {
  $("#service").toggleClass("js-on-directory");
} else if(dir.match(/price/)) {
  $("#price").toggleClass("js-on-directory");
} else if(dir.match(/access/)) {
  $("#access").toggleClass("js-on-directory");
}