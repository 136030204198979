


$(function() {

  moveTimer = 0;
  hideTimer = 0;
  // ホバーしている時
  $('.js-hover-panel').hover(function () {
      menu = $(this).find('.js-hover-panel-box');
      // 指定の要素内でマウスが動いている時
      $(this).on('mousemove', function(){
          // タイマーの解除
          clearTimeout(moveTimer);
          clearTimeout(hideTimer);

          $(".l-header-main").addClass('js-hover-on');
          $(".l-header-menu-item-link").addClass('js-hover-on');
          $(".l-header-contact").addClass('js-hover-on');
          $(".l-header-contact__link").addClass('js-hover-on');

          menu.fadeIn()
          // moveTimer = setTimeout(function(){
          //   menu.fadeIn()
          // }, 100);
      });
  // ホバーが外れている時
  },function(){
      menu = $(this).find('.js-hover-panel-box');
      // タイマーの解除
      clearTimeout(moveTimer);
      // タイマーのセット(マウスが外れて○秒後にhideを追加)
      hideTimer = setTimeout(function(){
          $(".l-header-main").removeClass('js-hover-on');
          $(".l-header-menu-item-link").removeClass('js-hover-on');
          $(".l-header-contact").removeClass('js-hover-on');
          $(".l-header-contact__link").removeClass('js-hover-on');
          menu.fadeOut()
      }, 100);
  });


  moveTimer2 = 0;
  hideTimer2 = 0;
  $('.js-hover-panel2').hover(function () {
    menu2 = $(this).find('.js-hover-panel-box2');
    // 指定の要素内でマウスが動いている時
    $(this).on('mousemove', function(){
        // タイマーの解除
        clearTimeout(moveTimer2);
        clearTimeout(hideTimer2);

        $(".l-header-main").addClass('js-hover-on');
        $(".l-header-menu-item-link").addClass('js-hover-on');
        $(".l-header-contact").addClass('js-hover-on');
        $(".l-header-contact__link").addClass('js-hover-on');

        menu2.fadeIn()
        // moveTimer = setTimeout(function(){
        //   menu.fadeIn()
        // }, 100);
    });
// ホバーが外れている時
},function(){
    menu2 = $(this).find('.js-hover-panel-box2');
    // タイマーの解除
    clearTimeout(moveTimer2);
    // タイマーのセット(マウスが外れて○秒後にhideを追加)
    hideTimer2 = setTimeout(function(){
        $(".l-header-main").removeClass('js-hover-on');
        $(".l-header-menu-item-link").removeClass('js-hover-on');
        $(".l-header-contact").removeClass('js-hover-on');
        $(".l-header-contact__link").removeClass('js-hover-on');
        menu2.fadeOut()
    }, 100);
});



    var nav = $('.l-header-main');
    nav.addClass('js-fixed');
    $('.js-fixed').css("box-shadow","0 0 0 0");

    offset = nav.offset();
    if(offset !== undefined){
      $(window).scroll(function () {
        offset = nav.offset();
        //console.log(offset.top);
        if(offset.top > 0) {
          $('.js-fixed').css("box-shadow","0px 7px 15px -4px rgb(0 0 0 / 10%)");
        }
        if(offset.top == 0) {
          $('.js-fixed').css("box-shadow","0 0 0 0");
        }
      });
    }
  

});