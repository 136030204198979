$(function () {
  // ページプラグインの埋め込みコードを返す。
  function pagePluginCode(w) {
    // 幅に応じて高さを変更する場合
    // if (w > 400) {
    //   var h = 300;
    // } else {
    //   var h = 200;
    // }
    return '<div class="fb-page" data-href="https://www.facebook.com/%E5%8E%9F%E8%B3%80%E4%BE%AF%E7%88%BE%E7%A8%8E%E7%90%86%E5%A3%AB%E4%BA%8B%E5%8B%99%E6%89%80-524557030910269/" data-tabs="timeline" data-width="500" data-height="513" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/%E5%8E%9F%E8%B3%80%E4%BE%AF%E7%88%BE%E7%A8%8E%E7%90%86%E5%A3%AB%E4%BA%8B%E5%8B%99%E6%89%80-524557030910269/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/%E5%8E%9F%E8%B3%80%E4%BE%AF%E7%88%BE%E7%A8%8E%E7%90%86%E5%A3%AB%E4%BA%8B%E5%8B%99%E6%89%80-524557030910269/">原賀侯爾税理士事務所</a></blockquote></div>';
  }
  
  // ページプラグインを追加する要素
  var facebookWrap = $('.facebook-wrapper');
  var fbBeforeWidth = ''; // 前回変更したときの幅
  var fbWidth = facebookWrap.width(); // 今回変更する幅
  var fbTimer = false;
  $(window).on('load resize', function () {
    if (fbTimer !== false) {
      clearTimeout(fbTimer);
    }
    fbTimer = setTimeout(function () {
      fbWidth = facebookWrap.width(); // 変更後の幅を取得
      // 前回の幅から変更があった場合のみ処理
      // スマホだとスクロール時にリサイズが発生することがあるため
      if (fbWidth != fbBeforeWidth) {
        facebookWrap.html(pagePluginCode(fbWidth)); // ページプラグインのコード変更
        window.FB.XFBML.parse(); // ページプラグインの再読み込み
        fbBeforeWidth = fbWidth; // 今回変更分を保存しておく
      }
    }, 200);
  });
});