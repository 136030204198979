

//---------------------------
//確認画面へボタン
//---------------------------
// $(".contact-form__submit-link").click(function () {
//   setTimeout(function(){
//     let classVal = $('form').attr('class');
//     let classVals = classVal.split(' ');
   
//     for (var i = 0; i < classVals.length; i++) {
//       console.log(classVals[i]);
//     }

//     if(classVals[1] == "custom-wpcf7c-confirmed"){
//       $('.step1').removeClass('-on');
//       $('.step2').addClass('-on');

//       $('.contact-form__personal-wrap').hide();
//     }else{
//       $('.step2').removeClass('-on');
//       $('.step1').addClass('-on');

//       $('.contact-form__personal-wrap').show();
//     }

//   },1500);
  
// });

//---------------------------
//戻るボタンクリック
//---------------------------
$(".c-form-submit__back").click(function () {

    $('.wpcf7-form').removeClass('custom-wpcf7c-confirmed');
    $('.step2').removeClass('-on');
    $('.step1').addClass('-on');

    //$('.contact-form__personal-wrap').show();
  
});


//---------------------------
//メールアドレスコピペ禁止
//---------------------------
$("input[type=e-mail]").on('copy cut paste',function(e) {
  e.preventDefault();
});